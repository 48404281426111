.ansi-black {
    color: #000000;
}
.ansi-black-background {
    background-color: #000000;
}
.ansi-blue {
    color: #3993d4;
}
.ansi-blue-background {
    background-color: #245980;
}
.ansi-bright-black {
    color: #595959;
}
.ansi-bright-black-background {
    background-color: #424242;
}
.ansi-bright-blue {
    color: #1FB0FF;
}
.ansi-bright-blue-background{
    background-color: #1778BD;
}
.ansi-bright-cyan {
    color: #00E5E5;
}
.ansi-bright-cyan-background{
    background-color: #006E6E;
}
.ansi-bright-green{
    color: #4FC414;
}
.ansi-bright-green-background{
    background-color: #458500;
}
.ansi-bright-magenta {
    color: #ED7EED;
}
.ansi-bright-magenta-background{
    background-color: #B247B2;
}
.ansi-bright-red{
    color: #FF4050;
}
.ansi-bright-red-background{
    background-color: #B82421;
}
.ansi-bright-white{
    color: #FFFFFF;
}
.ansi-bright-white-background{
    background-color: #FFFFFF;
}
.ansi-bright-yellow{
    color: #E5BF00;
}
.ansi-bright-yellow-background{
    background-color: #A87B00;
}
.ansi-cyan{
    color: #00A3A3;
}
.ansi-cyan-background{
    background-color: #154F4F;
}
.ansi-green{
    color: #5C962C;
}
.ansi-green-background{
    background-color: #39511F;
}
.ansi-magenta{
    color: #A771BF;
}
.ansi-magenta-background{
    background-color: #5C4069;
}
.ansi-red{
    color: #F0524F;
}
.ansi-red-background{
    background-color: #772E2C;
}
.ansi-bright-white {
    color: #808080;
}
.ansi-bright-white-background {
    background-color: #616161;
}
.ansi-yellow{
    color: #A68A0D;
}
.ansi-yellow-background{
    background-color: #5C4F17;
}
.ansi-bold{
    font-weight: bold;
}
.ansi-italic{
    font-style: italic;
}
.ansi-underline{
    text-decoration: underline;
}
.ansi-strikethrough{
    text-decoration: line-through;
}
.ansi-blink{
    animation: blinker 1s step-start infinite;
}
.ansi-hidden{
    visibility: hidden;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}
.ansi-palette-16 {
    color: #000000;
}
.ansi-palette-16-background {
    background-color: #000000;
}
.ansi-palette-17 {
    color: #00005f;
}
.ansi-palette-17-background {
    background-color: #00005f;
}
.ansi-palette-18 {
    color: #000087;
}
.ansi-palette-18-background {
    background-color: #000087;
}
.ansi-palette-19 {
    color: #0000af;
}
.ansi-palette-19-background {
    background-color: #0000af;
}
.ansi-palette-20 {
    color: #0000d7;
}
.ansi-palette-20-background {
    background-color: #0000d7;
}
.ansi-palette-21 {
    color: #0000ff;
}
.ansi-palette-21-background {
    background-color: #0000ff;
}
.ansi-palette-22 {
    color: #005f00;
}
.ansi-palette-22-background {
    background-color: #005f00;
}
.ansi-palette-23 {
    color: #005f5f;
}
.ansi-palette-23-background {
    background-color: #005f5f;
}
.ansi-palette-24 {
    color: #005f87;
}
.ansi-palette-24-background {
    background-color: #005f87;
}
.ansi-palette-25 {
    color: #005faf;
}
.ansi-palette-25-background {
    background-color: #005faf;
}
.ansi-palette-26 {
    color: #005fd7;
}
.ansi-palette-26-background {
    background-color: #005fd7;
}
.ansi-palette-27 {
    color: #005fff;
}
.ansi-palette-27-background {
    background-color: #005fff;
}
.ansi-palette-28 {
    color: #008700;
}
.ansi-palette-28-background {
    background-color: #008700;
}
.ansi-palette-29 {
    color: #00875f;
}
.ansi-palette-29-background {
    background-color: #00875f;
}
.ansi-palette-30 {
    color: #008787;
}
.ansi-palette-30-background {
    background-color: #008787;
}
.ansi-palette-31 {
    color: #0087af;
}
.ansi-palette-31-background {
    background-color: #0087af;
}
.ansi-palette-32 {
    color: #0087d7;
}
.ansi-palette-32-background {
    background-color: #0087d7;
}
.ansi-palette-33 {
    color: #0087ff;
}
.ansi-palette-33-background {
    background-color: #0087ff;
}
.ansi-palette-34 {
    color: #00af00;
}
.ansi-palette-34-background {
    background-color: #00af00;
}
.ansi-palette-35 {
    color: #00af5f;
}
.ansi-palette-35-background {
    background-color: #00af5f;
}
.ansi-palette-36 {
    color: #00af87;
}
.ansi-palette-36-background {
    background-color: #00af87;
}
.ansi-palette-37 {
    color: #00afaf;
}
.ansi-palette-37-background {
    background-color: #00afaf;
}
.ansi-palette-38 {
    color: #00afd7;
}
.ansi-palette-38-background {
    background-color: #00afd7;
}
.ansi-palette-39 {
    color: #00afff;
}
.ansi-palette-39-background {
    background-color: #00afff;
}
.ansi-palette-40 {
    color: #00d700;
}
.ansi-palette-40-background {
    background-color: #00d700;
}
.ansi-palette-41 {
    color: #00d75f;
}
.ansi-palette-41-background {
    background-color: #00d75f;
}
.ansi-palette-42 {
    color: #00d787;
}
.ansi-palette-42-background {
    background-color: #00d787;
}
.ansi-palette-43 {
    color: #00d7af;
}
.ansi-palette-43-background {
    background-color: #00d7af;
}
.ansi-palette-44 {
    color: #00d7d7;
}
.ansi-palette-44-background {
    background-color: #00d7d7;
}
.ansi-palette-45 {
    color: #00d7ff;
}
.ansi-palette-45-background {
    background-color: #00d7ff;
}
.ansi-palette-46 {
    color: #00ff00;
}
.ansi-palette-46-background {
    background-color: #00ff00;
}
.ansi-palette-47 {
    color: #00ff5f;
}
.ansi-palette-47-background {
    background-color: #00ff5f;
}
.ansi-palette-48 {
    color: #00ff87;
}
.ansi-palette-48-background {
    background-color: #00ff87;
}
.ansi-palette-49 {
    color: #00ffaf;
}
.ansi-palette-49-background {
    background-color: #00ffaf;
}
.ansi-palette-50 {
    color: #00ffd7;
}
.ansi-palette-50-background {
    background-color: #00ffd7;
}
.ansi-palette-51 {
    color: #00ffff;
}
.ansi-palette-51-background {
    background-color: #00ffff;
}
.ansi-palette-52 {
    color: #5f0000;
}
.ansi-palette-52-background {
    background-color: #5f0000;
}
.ansi-palette-53 {
    color: #5f005f;
}
.ansi-palette-53-background {
    background-color: #5f005f;
}
.ansi-palette-54 {
    color: #5f0087;
}
.ansi-palette-54-background {
    background-color: #5f0087;
}
.ansi-palette-55 {
    color: #5f00af;
}
.ansi-palette-55-background {
    background-color: #5f00af;
}
.ansi-palette-56 {
    color: #5f00d7;
}
.ansi-palette-56-background {
    background-color: #5f00d7;
}
.ansi-palette-57 {
    color: #5f00ff;
}
.ansi-palette-57-background {
    background-color: #5f00ff;
}
.ansi-palette-58 {
    color: #5f5f00;
}
.ansi-palette-58-background {
    background-color: #5f5f00;
}
.ansi-palette-59 {
    color: #5f5f5f;
}
.ansi-palette-59-background {
    background-color: #5f5f5f;
}
.ansi-palette-60 {
    color: #5f5f87;
}
.ansi-palette-60-background {
    background-color: #5f5f87;
}
.ansi-palette-61 {
    color: #5f5faf;
}
.ansi-palette-61-background {
    background-color: #5f5faf;
}
.ansi-palette-62 {
    color: #5f5fd7;
}
.ansi-palette-62-background {
    background-color: #5f5fd7;
}
.ansi-palette-63 {
    color: #5f5fff;
}
.ansi-palette-63-background {
    background-color: #5f5fff;
}
.ansi-palette-64 {
    color: #5f8700;
}
.ansi-palette-64-background {
    background-color: #5f8700;
}
.ansi-palette-65 {
    color: #5f875f;
}
.ansi-palette-65-background {
    background-color: #5f875f;
}
.ansi-palette-66 {
    color: #5f8787;
}
.ansi-palette-66-background {
    background-color: #5f8787;
}
.ansi-palette-67 {
    color: #5f87af;
}
.ansi-palette-67-background {
    background-color: #5f87af;
}
.ansi-palette-68 {
    color: #5f87d7;
}
.ansi-palette-68-background {
    background-color: #5f87d7;
}
.ansi-palette-69 {
    color: #5f87ff;
}
.ansi-palette-69-background {
    background-color: #5f87ff;
}
.ansi-palette-70 {
    color: #5faf00;
}
.ansi-palette-70-background {
    background-color: #5faf00;
}
.ansi-palette-71 {
    color: #5faf5f;
}
.ansi-palette-71-background {
    background-color: #5faf5f;
}
.ansi-palette-72 {
    color: #5faf87;
}
.ansi-palette-72-background {
    background-color: #5faf87;
}
.ansi-palette-73 {
    color: #5fafaf;
}
.ansi-palette-73-background {
    background-color: #5fafaf;
}
.ansi-palette-74 {
    color: #5fafd7;
}
.ansi-palette-74-background {
    background-color: #5fafd7;
}
.ansi-palette-75 {
    color: #5fafff;
}
.ansi-palette-75-background {
    background-color: #5fafff;
}
.ansi-palette-76 {
    color: #5fd700;
}
.ansi-palette-76-background {
    background-color: #5fd700;
}
.ansi-palette-77 {
    color: #5fd75f;
}
.ansi-palette-77-background {
    background-color: #5fd75f;
}
.ansi-palette-78 {
    color: #5fd787;
}
.ansi-palette-78-background {
    background-color: #5fd787;
}
.ansi-palette-79 {
    color: #5fd7af;
}
.ansi-palette-79-background {
    background-color: #5fd7af;
}
.ansi-palette-80 {
    color: #5fd7d7;
}
.ansi-palette-80-background {
    background-color: #5fd7d7;
}
.ansi-palette-81 {
    color: #5fd7ff;
}
.ansi-palette-81-background {
    background-color: #5fd7ff;
}
.ansi-palette-82 {
    color: #5fff00;
}
.ansi-palette-82-background {
    background-color: #5fff00;
}
.ansi-palette-83 {
    color: #5fff5f;
}
.ansi-palette-83-background {
    background-color: #5fff5f;
}
.ansi-palette-84 {
    color: #5fff87;
}
.ansi-palette-84-background {
    background-color: #5fff87;
}
.ansi-palette-85 {
    color: #5fffaf;
}
.ansi-palette-85-background {
    background-color: #5fffaf;
}
.ansi-palette-86 {
    color: #5fffd7;
}
.ansi-palette-86-background {
    background-color: #5fffd7;
}
.ansi-palette-87 {
    color: #5fffff;
}
.ansi-palette-87-background {
    background-color: #5fffff;
}
.ansi-palette-88 {
    color: #870000;
}
.ansi-palette-88-background {
    background-color: #870000;
}
.ansi-palette-89 {
    color: #87005f;
}
.ansi-palette-89-background {
    background-color: #87005f;
}
.ansi-palette-90 {
    color: #870087;
}
.ansi-palette-90-background {
    background-color: #870087;
}
.ansi-palette-91 {
    color: #8700af;
}
.ansi-palette-91-background {
    background-color: #8700af;
}
.ansi-palette-92 {
    color: #8700d7;
}
.ansi-palette-92-background {
    background-color: #8700d7;
}
.ansi-palette-93 {
    color: #8700ff;
}
.ansi-palette-93-background {
    background-color: #8700ff;
}
.ansi-palette-94 {
    color: #875f00;
}
.ansi-palette-94-background {
    background-color: #875f00;
}
.ansi-palette-95 {
    color: #875f5f;
}
.ansi-palette-95-background {
    background-color: #875f5f;
}
.ansi-palette-96 {
    color: #875f87;
}
.ansi-palette-96-background {
    background-color: #875f87;
}
.ansi-palette-97 {
    color: #875faf;
}
.ansi-palette-97-background {
    background-color: #875faf;
}
.ansi-palette-98 {
    color: #875fd7;
}
.ansi-palette-98-background {
    background-color: #875fd7;
}
.ansi-palette-99 {
    color: #875fff;
}
.ansi-palette-99-background {
    background-color: #875fff;
}
.ansi-palette-100 {
    color: #878700;
}
.ansi-palette-100-background {
    background-color: #878700;
}
.ansi-palette-101 {
    color: #87875f;
}
.ansi-palette-101-background {
    background-color: #87875f;
}
.ansi-palette-102 {
    color: #878787;
}
.ansi-palette-102-background {
    background-color: #878787;
}
.ansi-palette-103 {
    color: #8787af;
}
.ansi-palette-103-background {
    background-color: #8787af;
}
.ansi-palette-104 {
    color: #8787d7;
}
.ansi-palette-104-background {
    background-color: #8787d7;
}
.ansi-palette-105 {
    color: #8787ff;
}
.ansi-palette-105-background {
    background-color: #8787ff;
}
.ansi-palette-106 {
    color: #87af00;
}
.ansi-palette-106-background {
    background-color: #87af00;
}
.ansi-palette-107 {
    color: #87af5f;
}
.ansi-palette-107-background {
    background-color: #87af5f;
}
.ansi-palette-108 {
    color: #87af87;
}
.ansi-palette-108-background {
    background-color: #87af87;
}
.ansi-palette-109 {
    color: #87afaf;
}
.ansi-palette-109-background {
    background-color: #87afaf;
}
.ansi-palette-110 {
    color: #87afd7;
}
.ansi-palette-110-background {
    background-color: #87afd7;
}
.ansi-palette-111 {
    color: #87afff;
}
.ansi-palette-111-background {
    background-color: #87afff;
}
.ansi-palette-112 {
    color: #87d700;
}
.ansi-palette-112-background {
    background-color: #87d700;
}
.ansi-palette-113 {
    color: #87d75f;
}
.ansi-palette-113-background {
    background-color: #87d75f;
}
.ansi-palette-114 {
    color: #87d787;
}
.ansi-palette-114-background {
    background-color: #87d787;
}
.ansi-palette-115 {
    color: #87d7af;
}
.ansi-palette-115-background {
    background-color: #87d7af;
}
.ansi-palette-116 {
    color: #87d7d7;
}
.ansi-palette-116-background {
    background-color: #87d7d7;
}
.ansi-palette-117 {
    color: #87d7ff;
}
.ansi-palette-117-background {
    background-color: #87d7ff;
}
.ansi-palette-118 {
    color: #87ff00;
}
.ansi-palette-118-background {
    background-color: #87ff00;
}
.ansi-palette-119 {
    color: #87ff5f;
}
.ansi-palette-119-background {
    background-color: #87ff5f;
}
.ansi-palette-120 {
    color: #87ff87;
}
.ansi-palette-120-background {
    background-color: #87ff87;
}
.ansi-palette-121 {
    color: #87ffaf;
}
.ansi-palette-121-background {
    background-color: #87ffaf;
}
.ansi-palette-122 {
    color: #87ffd7;
}
.ansi-palette-122-background {
    background-color: #87ffd7;
}
.ansi-palette-123 {
    color: #87ffff;
}
.ansi-palette-123-background {
    background-color: #87ffff;
}
.ansi-palette-124 {
    color: #af0000;
}
.ansi-palette-124-background {
    background-color: #af0000;
}
.ansi-palette-125 {
    color: #af005f;
}
.ansi-palette-125-background {
    background-color: #af005f;
}
.ansi-palette-126 {
    color: #af0087;
}
.ansi-palette-126-background {
    background-color: #af0087;
}
.ansi-palette-127 {
    color: #af00af;
}
.ansi-palette-127-background {
    background-color: #af00af;
}
.ansi-palette-128 {
    color: #af00d7;
}
.ansi-palette-128-background {
    background-color: #af00d7;
}
.ansi-palette-129 {
    color: #af00ff;
}
.ansi-palette-129-background {
    background-color: #af00ff;
}
.ansi-palette-130 {
    color: #af5f00;
}
.ansi-palette-130-background {
    background-color: #af5f00;
}
.ansi-palette-131 {
    color: #af5f5f;
}
.ansi-palette-131-background {
    background-color: #af5f5f;
}
.ansi-palette-132 {
    color: #af5f87;
}
.ansi-palette-132-background {
    background-color: #af5f87;
}
.ansi-palette-133 {
    color: #af5faf;
}
.ansi-palette-133-background {
    background-color: #af5faf;
}
.ansi-palette-134 {
    color: #af5fd7;
}
.ansi-palette-134-background {
    background-color: #af5fd7;
}
.ansi-palette-135 {
    color: #af5fff;
}
.ansi-palette-135-background {
    background-color: #af5fff;
}
.ansi-palette-136 {
    color: #af8700;
}
.ansi-palette-136-background {
    background-color: #af8700;
}
.ansi-palette-137 {
    color: #af875f;
}
.ansi-palette-137-background {
    background-color: #af875f;
}
.ansi-palette-138 {
    color: #af8787;
}
.ansi-palette-138-background {
    background-color: #af8787;
}
.ansi-palette-139 {
    color: #af87af;
}
.ansi-palette-139-background {
    background-color: #af87af;
}
.ansi-palette-140 {
    color: #af87d7;
}
.ansi-palette-140-background {
    background-color: #af87d7;
}
.ansi-palette-141 {
    color: #af87ff;
}
.ansi-palette-141-background {
    background-color: #af87ff;
}
.ansi-palette-142 {
    color: #afaf00;
}
.ansi-palette-142-background {
    background-color: #afaf00;
}
.ansi-palette-143 {
    color: #afaf5f;
}
.ansi-palette-143-background {
    background-color: #afaf5f;
}
.ansi-palette-144 {
    color: #afaf87;
}
.ansi-palette-144-background {
    background-color: #afaf87;
}
.ansi-palette-145 {
    color: #afafaf;
}
.ansi-palette-145-background {
    background-color: #afafaf;
}
.ansi-palette-146 {
    color: #afafd7;
}
.ansi-palette-146-background {
    background-color: #afafd7;
}
.ansi-palette-147 {
    color: #afafff;
}
.ansi-palette-147-background {
    background-color: #afafff;
}
.ansi-palette-148 {
    color: #afd700;
}
.ansi-palette-148-background {
    background-color: #afd700;
}
.ansi-palette-149 {
    color: #afd75f;
}
.ansi-palette-149-background {
    background-color: #afd75f;
}
.ansi-palette-150 {
    color: #afd787;
}
.ansi-palette-150-background {
    background-color: #afd787;
}
.ansi-palette-151 {
    color: #afd7af;
}
.ansi-palette-151-background {
    background-color: #afd7af;
}
.ansi-palette-152 {
    color: #afd7d7;
}
.ansi-palette-152-background {
    background-color: #afd7d7;
}
.ansi-palette-153 {
    color: #afd7ff;
}
.ansi-palette-153-background {
    background-color: #afd7ff;
}
.ansi-palette-154 {
    color: #afff00;
}
.ansi-palette-154-background {
    background-color: #afff00;
}
.ansi-palette-155 {
    color: #afff5f;
}
.ansi-palette-155-background {
    background-color: #afff5f;
}
.ansi-palette-156 {
    color: #afff87;
}
.ansi-palette-156-background {
    background-color: #afff87;
}
.ansi-palette-157 {
    color: #afffaf;
}
.ansi-palette-157-background {
    background-color: #afffaf;
}
.ansi-palette-158 {
    color: #afffd7;
}
.ansi-palette-158-background {
    background-color: #afffd7;
}
.ansi-palette-159 {
    color: #afffff;
}
.ansi-palette-159-background {
    background-color: #afffff;
}
.ansi-palette-160 {
    color: #d70000;
}
.ansi-palette-160-background {
    background-color: #d70000;
}
.ansi-palette-161 {
    color: #d7005f;
}
.ansi-palette-161-background {
    background-color: #d7005f;
}
.ansi-palette-162 {
    color: #d70087;
}
.ansi-palette-162-background {
    background-color: #d70087;
}
.ansi-palette-163 {
    color: #d700af;
}
.ansi-palette-163-background {
    background-color: #d700af;
}
.ansi-palette-164 {
    color: #d700d7;
}
.ansi-palette-164-background {
    background-color: #d700d7;
}
.ansi-palette-165 {
    color: #d700ff;
}
.ansi-palette-165-background {
    background-color: #d700ff;
}
.ansi-palette-166 {
    color: #d75f00;
}
.ansi-palette-166-background {
    background-color: #d75f00;
}
.ansi-palette-167 {
    color: #d75f5f;
}
.ansi-palette-167-background {
    background-color: #d75f5f;
}
.ansi-palette-168 {
    color: #d75f87;
}
.ansi-palette-168-background {
    background-color: #d75f87;
}
.ansi-palette-169 {
    color: #d75faf;
}
.ansi-palette-169-background {
    background-color: #d75faf;
}
.ansi-palette-170 {
    color: #d75fd7;
}
.ansi-palette-170-background {
    background-color: #d75fd7;
}
.ansi-palette-171 {
    color: #d75fff;
}
.ansi-palette-171-background {
    background-color: #d75fff;
}
.ansi-palette-172 {
    color: #d78700;
}
.ansi-palette-172-background {
    background-color: #d78700;
}
.ansi-palette-173 {
    color: #d7875f;
}
.ansi-palette-173-background {
    background-color: #d7875f;
}
.ansi-palette-174 {
    color: #d78787;
}
.ansi-palette-174-background {
    background-color: #d78787;
}
.ansi-palette-175 {
    color: #d787af;
}
.ansi-palette-175-background {
    background-color: #d787af;
}
.ansi-palette-176 {
    color: #d787d7;
}
.ansi-palette-176-background {
    background-color: #d787d7;
}
.ansi-palette-177 {
    color: #d787ff;
}
.ansi-palette-177-background {
    background-color: #d787ff;
}
.ansi-palette-178 {
    color: #d7af00;
}
.ansi-palette-178-background {
    background-color: #d7af00;
}
.ansi-palette-179 {
    color: #d7af5f;
}
.ansi-palette-179-background {
    background-color: #d7af5f;
}
.ansi-palette-180 {
    color: #d7af87;
}
.ansi-palette-180-background {
    background-color: #d7af87;
}
.ansi-palette-181 {
    color: #d7afaf;
}
.ansi-palette-181-background {
    background-color: #d7afaf;
}
.ansi-palette-182 {
    color: #d7afd7;
}
.ansi-palette-182-background {
    background-color: #d7afd7;
}
.ansi-palette-183 {
    color: #d7afff;
}
.ansi-palette-183-background {
    background-color: #d7afff;
}
.ansi-palette-184 {
    color: #d7d700;
}
.ansi-palette-184-background {
    background-color: #d7d700;
}
.ansi-palette-185 {
    color: #d7d75f;
}
.ansi-palette-185-background {
    background-color: #d7d75f;
}
.ansi-palette-186 {
    color: #d7d787;
}
.ansi-palette-186-background {
    background-color: #d7d787;
}
.ansi-palette-187 {
    color: #d7d7af;
}
.ansi-palette-187-background {
    background-color: #d7d7af;
}
.ansi-palette-188 {
    color: #d7d7d7;
}
.ansi-palette-188-background {
    background-color: #d7d7d7;
}
.ansi-palette-189 {
    color: #d7d7ff;
}
.ansi-palette-189-background {
    background-color: #d7d7ff;
}
.ansi-palette-190 {
    color: #d7ff00;
}
.ansi-palette-190-background {
    background-color: #d7ff00;
}
.ansi-palette-191 {
    color: #d7ff5f;
}
.ansi-palette-191-background {
    background-color: #d7ff5f;
}
.ansi-palette-192 {
    color: #d7ff87;
}
.ansi-palette-192-background {
    background-color: #d7ff87;
}
.ansi-palette-193 {
    color: #d7ffaf;
}
.ansi-palette-193-background {
    background-color: #d7ffaf;
}
.ansi-palette-194 {
    color: #d7ffd7;
}
.ansi-palette-194-background {
    background-color: #d7ffd7;
}
.ansi-palette-195 {
    color: #d7ffff;
}
.ansi-palette-195-background {
    background-color: #d7ffff;
}
.ansi-palette-196 {
    color: #ff0000;
}
.ansi-palette-196-background {
    background-color: #ff0000;
}
.ansi-palette-197 {
    color: #ff005f;
}
.ansi-palette-197-background {
    background-color: #ff005f;
}
.ansi-palette-198 {
    color: #ff0087;
}
.ansi-palette-198-background {
    background-color: #ff0087;
}
.ansi-palette-199 {
    color: #ff00af;
}
.ansi-palette-199-background {
    background-color: #ff00af;
}
.ansi-palette-200 {
    color: #ff00d7;
}
.ansi-palette-200-background {
    background-color: #ff00d7;
}
.ansi-palette-201 {
    color: #ff00ff;
}
.ansi-palette-201-background {
    background-color: #ff00ff;
}
.ansi-palette-202 {
    color: #ff5f00;
}
.ansi-palette-202-background {
    background-color: #ff5f00;
}
.ansi-palette-203 {
    color: #ff5f5f;
}
.ansi-palette-203-background {
    background-color: #ff5f5f;
}
.ansi-palette-204 {
    color: #ff5f87;
}
.ansi-palette-204-background {
    background-color: #ff5f87;
}
.ansi-palette-205 {
    color: #ff5faf;
}
.ansi-palette-205-background {
    background-color: #ff5faf;
}
.ansi-palette-206 {
    color: #ff5fd7;
}
.ansi-palette-206-background {
    background-color: #ff5fd7;
}
.ansi-palette-207 {
    color: #ff5fff;
}
.ansi-palette-207-background {
    background-color: #ff5fff;
}
.ansi-palette-208 {
    color: #ff8700;
}
.ansi-palette-208-background {
    background-color: #ff8700;
}
.ansi-palette-209 {
    color: #ff875f;
}
.ansi-palette-209-background {
    background-color: #ff875f;
}
.ansi-palette-210 {
    color: #ff8787;
}
.ansi-palette-210-background {
    background-color: #ff8787;
}
.ansi-palette-211 {
    color: #ff87af;
}
.ansi-palette-211-background {
    background-color: #ff87af;
}
.ansi-palette-212 {
    color: #ff87d7;
}
.ansi-palette-212-background {
    background-color: #ff87d7;
}
.ansi-palette-213 {
    color: #ff87ff;
}
.ansi-palette-213-background {
    background-color: #ff87ff;
}
.ansi-palette-214 {
    color: #ffaf00;
}
.ansi-palette-214-background {
    background-color: #ffaf00;
}
.ansi-palette-215 {
    color: #ffaf5f;
}
.ansi-palette-215-background {
    background-color: #ffaf5f;
}
.ansi-palette-216 {
    color: #ffaf87;
}
.ansi-palette-216-background {
    background-color: #ffaf87;
}
.ansi-palette-217 {
    color: #ffafaf;
}
.ansi-palette-217-background {
    background-color: #ffafaf;
}
.ansi-palette-218 {
    color: #ffafd7;
}
.ansi-palette-218-background {
    background-color: #ffafd7;
}
.ansi-palette-219 {
    color: #ffafff;
}
.ansi-palette-219-background {
    background-color: #ffafff;
}
.ansi-palette-220 {
    color: #ffd700;
}
.ansi-palette-220-background {
    background-color: #ffd700;
}
.ansi-palette-221 {
    color: #ffd75f;
}
.ansi-palette-221-background {
    background-color: #ffd75f;
}
.ansi-palette-222 {
    color: #ffd787;
}
.ansi-palette-222-background {
    background-color: #ffd787;
}
.ansi-palette-223 {
    color: #ffd7af;
}
.ansi-palette-223-background {
    background-color: #ffd7af;
}
.ansi-palette-224 {
    color: #ffd7d7;
}
.ansi-palette-224-background {
    background-color: #ffd7d7;
}
.ansi-palette-225 {
    color: #ffd7ff;
}
.ansi-palette-225-background {
    background-color: #ffd7ff;
}
.ansi-palette-226 {
    color: #ffff00;
}
.ansi-palette-226-background {
    background-color: #ffff00;
}
.ansi-palette-227 {
    color: #ffff5f;
}
.ansi-palette-227-background {
    background-color: #ffff5f;
}
.ansi-palette-228 {
    color: #ffff87;
}
.ansi-palette-228-background {
    background-color: #ffff87;
}
.ansi-palette-229 {
    color: #ffffaf;
}
.ansi-palette-229-background {
    background-color: #ffffaf;
}
.ansi-palette-230 {
    color: #ffffd7;
}
.ansi-palette-230-background {
    background-color: #ffffd7;
}
.ansi-palette-231 {
    color: #ffffff;
}
.ansi-palette-231-background {
    background-color: #ffffff;
}
.ansi-palette-232 {
    color: #080808;
}
.ansi-palette-232-background {
    background-color: #080808;
}
.ansi-palette-233 {
    color: #121212;
}
.ansi-palette-233-background {
    background-color: #121212;
}
.ansi-palette-234 {
    color: #1c1c1c;
}
.ansi-palette-234-background {
    background-color: #1c1c1c;
}
.ansi-palette-235 {
    color: #262626;
}
.ansi-palette-235-background {
    background-color: #262626;
}
.ansi-palette-236 {
    color: #303030;
}
.ansi-palette-236-background {
    background-color: #303030;
}
.ansi-palette-237 {
    color: #3a3a3a;
}
.ansi-palette-237-background {
    background-color: #3a3a3a;
}
.ansi-palette-238 {
    color: #444444;
}
.ansi-palette-238-background {
    background-color: #444444;
}
.ansi-palette-239 {
    color: #4e4e4e;
}
.ansi-palette-239-background {
    background-color: #4e4e4e;
}
.ansi-palette-240 {
    color: #585858;
}
.ansi-palette-240-background {
    background-color: #585858;
}
.ansi-palette-241 {
    color: #626262;
}
.ansi-palette-241-background {
    background-color: #626262;
}
.ansi-palette-242 {
    color: #6c6c6c;
}
.ansi-palette-242-background {
    background-color: #6c6c6c;
}
.ansi-palette-243 {
    color: #767676;
}
.ansi-palette-243-background {
    background-color: #767676;
}
.ansi-palette-244 {
    color: #808080;
}
.ansi-palette-244-background {
    background-color: #808080;
}
.ansi-palette-245 {
    color: #8a8a8a;
}
.ansi-palette-245-background {
    background-color: #8a8a8a;
}
.ansi-palette-246 {
    color: #949494;
}
.ansi-palette-246-background {
    background-color: #949494;
}
.ansi-palette-247 {
    color: #9e9e9e;
}
.ansi-palette-247-background {
    background-color: #9e9e9e;
}
.ansi-palette-248 {
    color: #a8a8a8;
}
.ansi-palette-248-background {
    background-color: #a8a8a8;
}
.ansi-palette-249 {
    color: #b2b2b2;
}
.ansi-palette-249-background {
    background-color: #b2b2b2;
}
.ansi-palette-250 {
    color: #bcbcbc;
}
.ansi-palette-250-background {
    background-color: #bcbcbc;
}
.ansi-palette-251 {
    color: #c6c6c6;
}
.ansi-palette-251-background {
    background-color: #c6c6c6;
}
.ansi-palette-252 {
    color: #d0d0d0;
}
.ansi-palette-252-background {
    background-color: #d0d0d0;
}
.ansi-palette-253 {
    color: #dadada;
}
.ansi-palette-253-background {
    background-color: #dadada;
}
.ansi-palette-254 {
    color: #e4e4e4;
}
.ansi-palette-254-background {
    background-color: #e4e4e4;
}
.ansi-palette-255 {
    color: #eeeeee;
}
.ansi-palette-255-background {
    background-color: #eeeeee;
}